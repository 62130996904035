import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useUserTemplateStore from '../../store/user-templates-store.js';
import useUserStore from '../../store/user-store.js';
import useToolStore from '../../store/tools-store.js';
import CreateModal from './create-modal.jsx';
import UpdateModal from './update-modal.jsx';
import DeleteModal from '../../components/DeleteModal.jsx'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader.jsx';
import { renderLargeCell } from '../../utils/helper_functions.js';

const columnHelper = createColumnHelper();

const UserTemplates = () => {
    const { userTemplates, fetchUserTemplates, createUserTemplate, updateUserTemplate, deleteUserTemplate } = useUserTemplateStore();
    const { users, fetchUsers } = useUserStore();
    const { tools, fetchTools } = useToolStore();

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const [selectedUserTemplate, setSelectedUserTemplate] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchUserTemplates(), fetchUsers(), fetchTools()]);
            setIsLoading(false);
        };

        fetchData();
    }, [fetchUserTemplates, fetchUsers, fetchTools]);

    const data = useMemo(() => {
        const usersMap = new Map(users.map(user => [user.user_id, user.name]));
        const toolsMap = new Map(tools.map(tool => [tool.id, tool.name]));

        return userTemplates.map(userTemplate => ({
            ...userTemplate,
            user_name: usersMap.get(userTemplate.user_id) || '',
            tool_name: toolsMap.get(userTemplate.tool_id) || '',
        })).sort((a, b) => a.user_name.localeCompare(b.user_name));
    }, [userTemplates, users, tools]);


    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('name', {
                header: 'Name',
            }),
            columnHelper.accessor('value', {
                header: 'Value',
                cell: (info) => renderLargeCell(expandedRows, setExpandedRows, info, 'value'),
            }),
            columnHelper.accessor('user_name', { 
                header: 'User Name',
            }),
            columnHelper.accessor('tool_name', { 
                header: 'Tool Name',
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenEditModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        [expandedRows]
    );


    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);

    const handleOpenEditModal = (userTemplate) => {
        setSelectedUserTemplate(userTemplate);
        setIsUpdateModalOpen(true);
    };
    const handleOpenDeleteModal = (userTemplate) => {
        setSelectedUserTemplate(userTemplate);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsUpdateModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedUserTemplate(null);
    };

    const handleCreateUserTemplate = async (data) => {
        try {
            await createUserTemplate(data);
            toast.success('User template created successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to create userTemplate:', error);
            toast.error('Failed to create user template. Please try again.');
        }
    };

    const handleUpdateUserTemplate = async (id, updatedData) => {
        try {
            await updateUserTemplate(id, updatedData);
            toast.success('User template updated successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update user template:', error);
            toast.error('Failed to update user template. Please try again.');
            
        }
    };

    const handleDeleteUserTemplate = async (id) => {
        try {
            await deleteUserTemplate(id);
            toast.success('User template deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete userTemplate:', error);
            toast.error('Failed to delete user template. Please try again.');
        }
    };

    return (
        <div className="max-w-screen-3xl mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">User Template List</h1>
                <button
                    className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleOpenCreateModal}
                >
                    <FaPlus className="mr-2" /> Create New
                </button>
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                        isLoading ? (
                            <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]'/></td></tr>
                        ) : 
                            table.getRowModel().rows.length === 0 ? (
                                    <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No userTemplates found</p></td></tr>
                        ) :
                        table.getRowModel().rows.map((row) => (
                            <tr key={row.id} className="hover:bg-gray-50">
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                        <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <CreateModal
                isOpen={isCreateModalOpen}
                onRequestClose={handleCloseModal}
                onCreate={handleCreateUserTemplate}
                users={users}
                tools={tools}
            />
            <UpdateModal
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseModal}
                userTemplate={selectedUserTemplate}
                users={users}
                tools={tools}
                onUpdate={handleUpdateUserTemplate}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseModal}
                data={{...selectedUserTemplate, dataName: 'user template'}}
                onDelete={handleDeleteUserTemplate}
            />
        </div>
    );
};

export default UserTemplates;
