import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useToolFieldsStore = create((set) => ({
    toolFields: [],
    fetchAllToolFields: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tool-fields`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ toolFields: response.data.fields });
        } catch (error) {
            console.error('Failed to fetch tool fields:', error);
        }
    },
    createToolField: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/tool-fields`,
                data,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolFields: [...state.toolFields, response.data.field],
            }));
            return response.data.field;
        } catch (error) {
            console.error('Failed to create tool field:', error);
            throw error;
        }
    },
    updateToolField: async (field_id, tool_id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/tool-fields/${tool_id}/${field_id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolFields: state.toolFields.map((field) =>
                    field.id === field_id ? response.data.field : field
                ),
            }));
            return response.data.field;
        } catch (error) {
            console.error('Failed to update tool field:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteToolField: async (tool_id, field_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/tool-fields/${tool_id}/${field_id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                toolFields: state.toolFields.filter((field) => field.id !== field_id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete tool field:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useToolFieldsStore;