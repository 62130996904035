import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useToolInputsStore from '../../store/tool-inputs-store';
import useToolStore from '../../store/tools-store';
import CreateModal from './create-modal';
import UpdateModal from './update-modal';
import DeleteModal from '../../components/DeleteModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const ToolInputs = () => {
    const { toolInputs, fetchAllToolInputs, createToolInput, updateToolInput, deleteToolInput } = useToolInputsStore();
    const { tools, fetchTools } = useToolStore();

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedToolInput, setSelectedToolInput] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchAllToolInputs(), fetchTools()]);
            setIsLoading(false);
        };

        fetchData();
    }, [fetchAllToolInputs, fetchTools]);

    const data = useMemo(() => {
        return toolInputs.map((input) => {
                const tool = tools.find(tool => tool.id === input.tool_id);
                return {
                    ...input,
                    tool_name: tool?.name || " ",
                };
            }).sort((a, b) => a.tool_name.localeCompare(b.tool_name));
    }, [toolInputs, tools]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('type', {
                header: 'Type',
            }),
            columnHelper.accessor('format', {
                header: 'Format',
                cell: info => info.getValue() ? info.getValue() : '-',
            }),
            columnHelper.accessor('tool_name', {
                header: 'Tool Name',
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenEditModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);

    const handleOpenEditModal = (toolInput) => {
        setSelectedToolInput(toolInput);
        setIsUpdateModalOpen(true);
    };
    const handleOpenDeleteModal = (toolInput) => {
        setSelectedToolInput(toolInput);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsUpdateModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedToolInput(null);
    };

    const handleCreateToolInput = async (type, format, tool_id) => {
        try {
            await createToolInput(type, format, tool_id);
            toast.success('Tool input created successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to create tool input:', error);
            toast.error('Failed to create tool input. Please try again.');
        }
    };

    const handleUpdateToolInput = async (input_id, tool_id, toolInputData) => {
        try {
            await updateToolInput(input_id, tool_id, toolInputData);
            toast.success('Tool input updated successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update tool input:', error);
            toast.error('Failed to update tool input. Please try again.');
        }
    };

    const handleDeleteToolInput = async (input_id, tool_id) => {
        try {
            await deleteToolInput(tool_id, input_id);
            toast.success('Tool input deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete tool input:', error);
            toast.error('Failed to delete tool input. Please try again.');
        }
    };

    return (
        <div className="max-w-screen-3xl mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Tool Input List</h1>
                <button
                    className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleOpenCreateModal}
                >
                    <FaPlus className="mr-2" /> Create New
                </button>
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            isLoading ? (
                                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
                            ) :
                                table.getRowModel().rows.length === 0 ? (
                                    <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No tool inputs found</p></td></tr>
                                ) :
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-50">
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                    <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                    </tbody>
                </table>
            </div>
            <CreateModal
                isOpen={isCreateModalOpen}
                onRequestClose={handleCloseModal}
                onCreate={handleCreateToolInput}
                tools={tools}
            />
            <UpdateModal
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseModal}
                toolInput={selectedToolInput}
                onUpdate={handleUpdateToolInput}
                tools={tools}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseModal}
                data={{ ...selectedToolInput, dataName: 'tool input' }}
                onDelete={handleDeleteToolInput}
            />
        </div>
    );
};

export default ToolInputs;
