import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaKey, FaPen, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { VscClearAll } from "react-icons/vsc";
import useUserStore from '../../store/user-store';
import useOrganisationStore from '../../store/organisations-store';
import CreateUserModal from './create-user-modal';
import EditUserModal from './edit-user-modal';
import DeleteModal from '../../components/DeleteModal.jsx'
import ResetPasswordModal from '../../components/reset-password'; // Import the new modal
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { formatDate } from '../../utils/helper_functions';
import useDebounce from '../../utils/useDebounce';

const columnHelper = createColumnHelper();

const UsersTable = () => {
  const { users, fetchUsers, createUser, editUser, deleteUser, setUserPassword } = useUserStore();
  const { organisations, fetchOrganisations } = useOrganisationStore();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [filterAdmins, setFilterAdmins] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);
  const search = useMemo(() => {
    return `${debouncedGlobalFilter}`.trim();
  }, [debouncedGlobalFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchUsers(search, selectedOrg, filterAdmins), fetchOrganisations()]);
      setIsLoading(false);
    }
    fetchData();
  }, [fetchUsers, fetchOrganisations, search, selectedOrg, filterAdmins]);

  const data = useMemo(() => {
    return users.sort((a, b) => a.name.localeCompare(b.name))
      .map((user) => {
        const organisation = organisations.find(org => org.id === user.organisation_id);
        return {
          ...user,
          organisation: organisation?.name,
        };
      });
  }, [users, organisations]);

  const sortedOrganisations = useMemo(() => organisations.sort((a, b) => a.name.localeCompare(b.name)), [organisations]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('user_id', {
        header: 'User ID',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('organisation', {
        header: 'Organisation Name',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('is_admin', {
        header: 'Is Admin',
        cell: info => info.getValue() === true ? <FaCheck className="text-green-500" /> : <FaTimes className="text-red-600" />,
      }),
      columnHelper.accessor('email', {
        header: 'Email',
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('docx_template_s3_bucket_name', {
        header: 'S3 Bucket Name',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('docx_template_file_name', {
        header: 'Template File Name',
        cell: info => info.getValue() ? info.getValue() : '-',
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4 items-center">
            <FaPen
              title="Edit"
              onClick={() => handleOpenEditModal(row.original)}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => handleOpenDeleteModal(row.original)}
              className="cursor-pointer text-red-600"
            />
            <FaKey
              title="Reset Password"
              onClick={() => handleOpenResetPasswordModal(row.original)}
              className="cursor-pointer text-yellow-600"
            />
          </div>
        ),
      }),
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { search},
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  
  // Handlers for modals
  const handleOpenCreateModal = () => setIsCreateModalOpen(true);
  const handleOpenEditModal = (user) => {
    console.log('Opening edit modal for user:', user); // Debug log
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };
  const handleOpenDeleteModal = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };
  const handleOpenResetPasswordModal = (user) => {
    setSelectedUser(user);
    setIsResetPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsResetPasswordModalOpen(false);
    setSelectedUser(null);
  };

  const handleCreateUser = async (userData) => {
    await createUser(userData);
  };

  const handleEditUser = async (id, userData) => {
    await editUser(id, userData);
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id);
      toast.success('User deleted successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to delete user:', error);
      toast.error('Failed to delete user. Please try again.');
    }
  };

  const handleResetPassword = async (userId, newPassword) => {
    try {
      await setUserPassword(userId, newPassword);
      toast.success('Password reset successfully');
      handleCloseModal();
    } catch (error) {
      console.error('Failed to reset password:', error);
      toast.error('Failed to reset password. Please try again.');
    }
  };

  const noUsersFound = table.getRowModel().rows.length === 0;

  return (
    <div className="max-w-screen-3xl mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">User List</h1>
        <button
          className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={handleOpenCreateModal}
        >
          <FaPlus className="mr-2" /> Create New
        </button>
      </div>
      <div className="flex gap-4 mb-4">
        <input
          type="text"
          value={globalFilter}
          autoComplete="off"
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <select
          name="org_id"
          value={selectedOrg || ''}
          onChange={(e) => { setSelectedOrg(e.target.value) }}
          className={`px-4 border rounded-md shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 ${!selectedOrg && 'text-gray-500'}`}
        >
          <option value="" className='text-gray-500'>Select Organisation</option>
          {sortedOrganisations.map((org) => (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => setFilterAdmins(!filterAdmins)}
          className={`flex items-center gap-2 px-5 border rounded-md shadow-sm bg-white text-gray-500 text-sm cursor-pointer transition-all duration-100 transform
            ${filterAdmins ? 'border-indigo-900 bg-gray-50 scale-105 active:scale-95' : 'hover:bg-gray-50 active:scale-95'}`}
        >
          Select Admins
        </button>
        {
          search || selectedOrg || filterAdmins ? (
            <div 
              className='flex items-center px-2 border rounded-md shadow-sm bg-white cursor-pointer text-gray-400 hover:text-gray-500 ' 
              title='Clear'
              onClick={() => {
                setGlobalFilter('');
                setSelectedOrg('');
                setFilterAdmins(false);
              }}
            >
              <VscClearAll className="self-center size-6"/>
            </div>
          ) : null
        }
      </div>
      <div className={`overflow-x-auto relative border border-gray-300 rounded-[3px] ${isLoading && 'h-[65vh]'} ${noUsersFound && 'h-[50vh]'}`}>
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700
                      ${header.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50 sm:min-w-36' : ''}
                      ${header.column.id.length < 10 && header.column.id !== 'actions' ? 'min-w-36 sm:min-w-44' : ''}
                      ${header.column.id.length >= 10 && header.column.id.length < 15 ? 'min-w-52 sm:min-w-52' : ''}
                      ${header.column.id.length >= 15 ? 'min-w-64 sm:min-w-64' : ''}
                    `}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2" /></td></tr>
              ) :
                noUsersFound ? (
                  <tr><td colSpan={columns.length}><p className="absolute top-[58%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">No users found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} 
                          className={`py-3 px-4 border-b border-gray-200 text-sm text-gray-700 ${cell.column.id === 'actions' ? 'sticky -right-[1px] bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
              ))}
          </tbody>
        </table>
      </div>
      <CreateUserModal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseModal}
        onCreate={handleCreateUser}
        organisations={sortedOrganisations}
      />
      <EditUserModal
        isOpen={isEditModalOpen}
        onRequestClose={handleCloseModal}
        user={selectedUser}
        onEdit={handleEditUser}
        organisations={sortedOrganisations}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseModal}
        data={{...selectedUser, dataName: 'user'}}
        onDelete={handleDeleteUser}
      />
      <ResetPasswordModal
        isOpen={isResetPasswordModalOpen}
        onRequestClose={handleCloseModal}
        userId={selectedUser?.user_id} // Pass userId to the ResetPasswordModal
        setUserPassword={handleResetPassword}
      />
      {/* <ToastContainer /> */}
    </div>
  );
};

export default UsersTable;
