import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const usePlanStore = create((set) => ({
    plans: [],
    fetchPlans: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/plans`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ plans: response.data.plans });
        } catch (error) {
            console.error('Failed to fetch plans:', error);
        }
    },
    createPlan: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/plans`, data, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({ plans: [...state.plans, response.data.plan] }));
            return response.data.plan;
        } catch (error) {
            console.error('Failed to create plan:', error);
            throw error;
        }
    },
    updatePlan: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/plans/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                plans: state.plans.map((plan) =>
                    plan.id === id ? response.data.plan : plan
                ),
            }));
            return response.data.plan;
        } catch (error) {
            console.error('Failed to update plan:', error);
            throw new Error(error.response.data.message);
        }
    },
    deletePlan: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/plans/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                plans: state.plans.filter((plan) => plan.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete plan:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default usePlanStore;
