import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useUserTemplateStore = create((set) => ({
    userTemplate: null,
    userTemplates: [],
    fetchUserTemplates: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/user_templates`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ userTemplates: response.data.user_templates });
        } catch (error) {
            console.error('Failed to fetch user templates:', error);
        }
    },
    fetchUserTemplateById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/user_templates/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.user_template;
        } catch (error) {
            console.error(`Failed to fetch user template with id ${id}:`, error);
            throw error;
        }
    },
    createUserTemplate: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/user_templates`, data, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({ userTemplates: [...state.userTemplates, response.data.user_template] }));
            return response.data.user_template;
        } catch (error) {
            console.error('Failed to create user template:', error);
            throw error;
        }
    },
    updateUserTemplate: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/user_templates/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                userTemplates: state.userTemplates.map((userTemplate) =>
                    userTemplate.id === id ? response.data.user_template : userTemplate
                ),
            }));
            console.log("response: ", response.data.user_template);
            return response.data.user_template;
        } catch (error) {
            console.error('Failed to update user template:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteUserTemplate: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/user_templates/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                userTemplates: state.userTemplates.filter((userTemplate) => userTemplate.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete user template:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useUserTemplateStore;
