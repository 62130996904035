import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useOrganisationStore = create((set) => ({
    organisation: null,
    organisations: [],
    fetchOrganisations: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/organisations`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ organisations: response.data.organisations });
        } catch (error) {
            console.error('Failed to fetch organisations:', error);
        }
    },
    fetchOrganisationById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/organisations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.organisation;
        } catch (error) {
            console.error(`Failed to fetch organisation with id ${id}:`, error);
            throw error;
        }
    },
    createOrganisation: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/organisations`, data, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({ organisations: [...state.organisations, response.data.organisation] }));
            return response.data.organisation;
        } catch (error) {
            console.error('Failed to create organisation:', error);
            throw error;
        }
    },
    updateOrganisation: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/organisations/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                organisations: state.organisations.map((organisation) =>
                    organisation.id === id ? response.data.organisation : organisation
                ),
            }));
            return response.data.organisation;
        } catch (error) {
            console.error('Failed to update organisation:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteOrganisation: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/organisations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                organisations: state.organisations.filter((organisation) => organisation.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete organisation:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useOrganisationStore;
