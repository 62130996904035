import React, { useEffect, useMemo, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getFilteredRowModel,
    flexRender,
    createColumnHelper,
} from '@tanstack/react-table';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
import useToolPromptsStore from '../../store/tool-prompts-store';
import useToolStore from '../../store/tools-store';
import CreateModal from './create-modal';
import UpdateModal from './update-modal';
import DeleteModal from '../../components/DeleteModal';
import { toast } from 'react-toastify';
import { renderLargeCell } from '../../utils/helper_functions';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';

const columnHelper = createColumnHelper();

const ToolPrompts = () => {
    const { toolPrompts, fetchAllToolPrompts, createToolPrompt, updateToolPrompt, deleteToolPrompt } = useToolPromptsStore();
    const { tools, fetchTools } = useToolStore();

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [selectedToolPrompt, setSelectedToolPrompt] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([fetchAllToolPrompts(), fetchTools()]);
            setIsLoading(false);
        };

        fetchData();
    }, [fetchAllToolPrompts, fetchTools]);

    const data = useMemo(() => {
        return toolPrompts.map((prompt) => {
                const tool = tools.find(tool => tool.id === prompt.tool_id);
                return {
                    ...prompt,
                    tool_name: tool?.name || " ",
                };
            }).sort((a, b) => a.tool_name.localeCompare(b.tool_name) || a.precedence - b.precedence);
    }, [toolPrompts, tools]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
            }),
            columnHelper.accessor('name', {
                header: 'Name',
            }),
            columnHelper.accessor('value', {
                header: 'Value',
                cell: (info) => renderLargeCell(expandedRows, setExpandedRows, info, 'value'),
            }),
            columnHelper.accessor('precedence', {
                header: 'Precedence',
            }),
            columnHelper.accessor('tool_name', {
                header: 'Tool Name',
            }),
            columnHelper.accessor('actions', {
                header: 'Actions',
                cell: ({ row }) => (
                    <div className="flex space-x-4 items-center">
                        <FaPen
                            title="Edit"
                            onClick={() => handleOpenEditModal(row.original)}
                            className="cursor-pointer text-blue-500"
                        />
                        <FaTrash
                            title="Delete"
                            onClick={() => handleOpenDeleteModal(row.original)}
                            className="cursor-pointer text-red-600"
                        />
                    </div>
                ),
            }),
        ],
        [expandedRows]
    );

    const table = useReactTable({
        data,
        columns,
        state: { globalFilter },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    const handleOpenCreateModal = () => setIsCreateModalOpen(true);

    const handleOpenEditModal = (toolPrompt) => {
        setSelectedToolPrompt(toolPrompt);
        setIsUpdateModalOpen(true);
    };
    const handleOpenDeleteModal = (toolPrompt) => {
        setSelectedToolPrompt(toolPrompt);
        setIsDeleteModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsCreateModalOpen(false);
        setIsUpdateModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedToolPrompt(null);
    };

    const handleCreateToolPrompt = async (data) => {
        try {
            await createToolPrompt(data);
            toast.success('Tool prompt created successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to create tool prompt:', error);
            toast.error('Failed to create tool prompt. Please try again.');
        }
    };

    const handleUpdateToolPrompt = async (id, updatedData) => {
        try {
            await updateToolPrompt(id, updatedData);
            toast.success('Tool prompt updated successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to update tool prompt:', error);
            toast.error('Failed to update tool prompt. Please try again.');
        }
    };

    const handleDeleteToolPrompt = async (id) => {
        try {
            await deleteToolPrompt(id);
            toast.success('Tool prompt deleted successfully');
            handleCloseModal();
        } catch (error) {
            console.error('Failed to delete tool prompt:', error);
            toast.error('Failed to delete tool prompt. Please try again.');
        }
    };

    return (
        <div className="max-w-screen-3xl mx-auto mt-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Tool Prompt List</h1>
                <button
                    className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={handleOpenCreateModal}
                >
                    <FaPlus className="mr-2" /> Create New
                </button>
            </div>
            <div className="mb-4">
                <input
                    type="text"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>
            <div className="overflow-x-auto shadow-md rounded-lg">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead className="bg-gray-50">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        <span>
                                            {header.column.getIsSorted()
                                                ? header.column.getIsSortedDesc()
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {
                            isLoading ? (
                                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
                            ) :
                                table.getRowModel().rows.length === 0 ? (
                                    <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No tool prompts found</p></td></tr>
                                ) :
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-50">
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                    <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                    </tbody>
                </table>
            </div>
            <CreateModal
                isOpen={isCreateModalOpen}
                onRequestClose={handleCloseModal}
                onCreate={handleCreateToolPrompt}
                tools={tools}
            />
            <UpdateModal
                isOpen={isUpdateModalOpen}
                onRequestClose={handleCloseModal}
                toolPrompt={selectedToolPrompt}
                onUpdate={handleUpdateToolPrompt}
                tools={tools}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={handleCloseModal}
                data={{ ...selectedToolPrompt, dataName: 'tool prompt' }}
                onDelete={handleDeleteToolPrompt}
            />
        </div>
    );
};

export default ToolPrompts;
