import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useVocabularyStore = create((set) => ({
  vocabulary: [],
  fetchVocabulary: async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/vocabulary`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set({ vocabulary: response.data.vocabulary });
    } catch (error) {
      console.error('Failed to fetch vocabulary:', error);
    }
  },
  createVocabulary: async (data) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/vocabulary`, data, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({ vocabulary: [...state.vocabulary, response.data.vocabulary] }));
      return response.data.vocabulary;
    } catch (error) {
      console.error('Failed to create vocabulary:', error);
      throw error;
    }
  },
  updateVocabulary: async (id, updatedData) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/vocabulary/${id}`, updatedData, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({
        vocabulary: state.vocabulary.map((vocabulary) =>
          vocabulary.id === id ? response.data.vocabulary : vocabulary
        ),
      }));
      return response.data.vocabulary;
    } catch (error) {
      console.error('Failed to update vocabulary:', error);
      throw new Error(error.response.data.message);
    }
  },
  deleteVocabulary: async (id) => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/vocabulary/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken.toString()}`,
          'Content-Type': 'application/json',
        },
      });
      set((state) => ({
        vocabulary: state.vocabulary.filter((vocabulary) => vocabulary.id !== id),
      }));
      return response.data.message;
    } catch (error) {
      console.error('Failed to delete vocabulary:', error);
      throw new Error(error.response.data.message);
    }
  },
}));

export default useVocabularyStore;
