import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { trimData } from '../../utils/helper_functions';

const UpdateModal = ({ isOpen, onRequestClose, toolPrompt, onUpdate, tools }) => {
    const [formData, setFormData] = useState({
        name: '',
        value: '',
        precedence: '',
        tool_id: '',
    });

    const [initialData, setInitialData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (toolPrompt) {
            const initialFormData = {
                name: toolPrompt.name || '',
                value: toolPrompt.value || '',
                tool_id: toolPrompt.tool_id || '',
                precedence: toolPrompt.precedence || '',
            };
            setFormData(initialFormData);
            setInitialData(initialFormData);
        }
    }, [toolPrompt]);

    useEffect(() => {
        if (initialData) {
            const trimmedFormData = trimData(formData);
            const trimmedInitialData = trimData(initialData);

            const isFormDataChanged = JSON.stringify(trimmedFormData) !== JSON.stringify(trimmedInitialData);
            setIsSaveDisabled(!isFormDataChanged);
        }
    }, [formData, initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const trimmedFormData = trimData(formData);
        await onUpdate(toolPrompt.id, trimmedFormData);
        setIsLoading(false);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={onRequestClose}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
                <h2 className="text-xl font-semibold mb-4">Edit Tool Prompt</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col mb-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div className="flex flex-col mb-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Value</label>
                            <textarea
                                type="text"
                                name="value"
                                value={formData.value}
                                onChange={handleChange}
                                className="px-4 py-2 h-60 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                rows="4"
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className="flex flex-col mb-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Tool</label>
                                <select
                                    name="tool_id"
                                    value={formData.tool_id}
                                    onChange={handleChange}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <option value="" disabled>Select Tool</option>
                                    {tools.map((tool) => (
                                        <option key={tool.id} value={tool.id}>
                                            {tool.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex flex-col mb-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Precedence</label>
                                <input
                                    type="number"
                                    name="precedence"
                                    value={formData.precedence}
                                    onChange={(e) => { setFormData({ ...formData, precedence: parseInt(e.target.value, 10) }) }}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={onRequestClose}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isSaveDisabled && 'opacity-80 cursor-not-allowed'}`}
                            disabled={isSaveDisabled}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateModal;