import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useToolPromptsStore = create((set) => ({
    toolPrompts: [],
    fetchAllToolPrompts: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tool-prompts`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ toolPrompts: response.data.toolPrompts });
        } catch (error) {
            console.error('Failed to fetch tool prompts:', error);
        }
    },
    createToolPrompt: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/tool-prompts`, data,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolPrompts: [...state.toolPrompts, response.data.toolPrompt],
            }));
            return response.data.toolPrompt;
        } catch (error) {
            console.error('Failed to create tool prompt:', error);
            throw error;
        }
    },
    updateToolPrompt: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/tool-prompts/${id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolPrompts: state.toolPrompts.map((prompt) =>
                    prompt.id === id ? response.data.toolPrompt : prompt
                ),
            }));
            return response.data.toolPrompt;
        } catch (error) {
            console.error('Failed to update tool prompt:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteToolPrompt: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/tool-prompts/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                toolPrompts: state.toolPrompts.filter((prompt) => prompt.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete tool prompt:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useToolPromptsStore;