import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CreateModal = ({ isOpen, onRequestClose, onCreate }) => {
    const initialFormData = {
        name: '',
        tokens: 0,
        max_word_limit: 0,
        paying_customer: false,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = async () => {
        setIsLoading(true);
        await onCreate(formData);
        setIsLoading(false);
        handleCancel();
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        onRequestClose();
    };

    const isDisabled = !formData.name;

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
                <h2 className="text-xl font-semibold mb-4">Create Organisation</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex flex-col mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Tokens</label>
                                <input
                                    type="number"
                                    name="tokens"
                                    value={formData.tokens}
                                    onChange={(e) => setFormData({ ...formData, tokens: e.target.value })}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Max Word Limit</label>
                                <input
                                    type="number"
                                    name="max_word_limit"
                                    value={formData.max_word_limit}
                                    onChange={(e) => setFormData({ ...formData, max_word_limit: e.target.value })}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <label className="block text-gray-700 text-sm font-bold mr-2 mt-2">Paying Customer</label>
                            <input
                                type="checkbox"
                                name="paying_customer"
                                checked={formData.paying_customer}
                                onChange={(e) => setFormData({ ...formData, paying_customer: e.target.checked })}
                                className="form-checkbox h-5 w-5 text-indigo-600 mt-2"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleCreate}
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateModal;