import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import userIcon from '../../images/user-icon.png';
import copyIcon from '../../images/copy-icon.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import recordingIcon from '../../images/recording-02.png';
import downloadDocIcon from '../../images/download-doc.png';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getSignedUrl } from '../../utils/helper_functions';
import { fetchAuthSession } from 'aws-amplify/auth';
import loading from '../../images/generating.gif';
import Notification from '../../components/Notification'

const Report = ({ report, onRequestClose }) => {

    const [downloadingReport, setDownloadingReport] = useState(false);
    const [downloadingAudio, setDownloadingAudio] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const reportText = useMemo(() => `${report?.report}\n`?.split(" \\\n")?.join("\n"), [report]);

    const processedReport = useMemo(() => {
        if (report && report.report) {
            let updatedReport = report.report;
            updatedReport = updatedReport.replace(/placeholder_name/g, report.patient_name);
            updatedReport = updatedReport.replace(/placeholder_doctor/g, report.doctor_name);
            updatedReport = updatedReport.replace(/placeholder_clinic/g, report.clinic_name);
            return `${updatedReport}\n`?.split(" \\\n")?.join("\n");
        }
        return '';
    }, [report]);

    const components = {
        h1: ({ children, ...props }) => <h1 style={{ fontSize: '2em', fontWeight: 'bold', margin: '20px 0 10px' }} {...props}>{children}</h1>,
        h2: ({ children, ...props }) => <h2 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '15px 0 10px' }} {...props}>{children}</h2>,
        h3: ({ children, ...props }) => <h3 style={{ fontSize: '1.17em', fontWeight: 'bold', margin: '10px 0 5px' }} {...props}>{children}</h3>,
        p: ({ children, ...props }) => <p style={{ margin: '10px 0' }} {...props}>{children}</p>,
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: false };
        return date.toLocaleString('en-US', options);
    };

    const downloadAudioFile = async () => {
        try {
            if (downloadingAudio) return;

            setDownloadingAudio(true);
            const signedUrl = await getSignedUrl({ id: report?.id, name: report?.id, type: "download", meta_type: report?.meta_type })
            window.open(signedUrl, '_self')
        } catch (error) {
            console.log("Downloading error ", error);
            toast.error('Error while downloading the file. Please try again')
        } finally {
            setDownloadingAudio(false);
        }
    }

    const convertMdtoDocx = async (report) => {
        try {
            if (downloadingReport) return;

            setDownloadingReport(true);
            const id = report.id;
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await fetch(`${process.env.REACT_APP_ADMIN_API}/admin/md-to-docx-pandoc/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                }
            });
            console.log('result', response);

            if (!response.ok) {
                toast.error('Error while downloading the file. Please try again')
                console.log('Error, could not fetch converted docx');
                return;
            }

            const docxDownloadLink = await response.json();
            const { downloadUrl } = docxDownloadLink
            console.log('download link,', docxDownloadLink);
            console.log('url,', downloadUrl);

            const link = document.createElement('a');
            link.download = `Report - ${report.row_number}.docx`;
            link.href = downloadUrl;
            link.click();
        } catch (err) {
            console.log('Failed to convert report to docx, Full Error - ', err);
            toast.error('Error while downloading the file. Please try again')
        } finally {
            setDownloadingReport(false);
        }
    }

    const handleCopyClicked = () => {
        setShowNotification(true)
        setTimeout(() => {
            setShowNotification(false);
        }, 2000);
    }

    return (
        <>
        <div className="relative card-shadow no-scrollbar max-w-[764px] w-[130vw] md:w-[80vw] lg:w-[60vw] h-[95vh] sm:h-[110vh] rounded-md shadow-md overflow-auto scale-[0.85]">
            <FaTimes className="fixed top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={onRequestClose} size={12} />
            <div>
                <div className='flex md:flex-row flex-col md:gap-2 gap-2.5 justify-between border-b border-b-1 border-b-[#E5E7EC] p-[20px] sm:p-[32px]'>
                    <div className='flex items-center md:justify-start justify-center flex-col sm:flex-row md:gap-4'>
                        <div className='w-[44px] h-[44px] border border-[#E2E4E9] rounded-full flex justify-center items-center md:mr-2'>
                            <img src={userIcon} alt='user icon' width={20} height={20} />
                        </div>
                        <div className='ml-0 mt-[10px] sm:mt-0 md:text-left text-center'>
                            <p className='text-[#505050] inline-block text-[14px] font-SuisseIntlLight font-normal justify-center'>
                                <span className="wg-ignore">#{report.row_number}</span>
                                <span className="font-normal ml-[4px] mr-[1px] text-black text-[12px]">&middot;</span>
                                <span className='mx-0'>{` ${formatDate(report.created_at)}`}</span>
                            </p>
                            <p className='text-black text-[16px] md:block hidden capitalize mt-[4px] wg-ignore break-words'>{report.patient_name}</p>
                        </div>
                    </div>
                    <div className='flex sm:flex-row flex-col gap-2.5 md:gap-0 items-center md:justify-center '>
                        <div className='flex items-center w-full justify-center'>
                            {!downloadingAudio && <div onClick={downloadAudioFile} style={{ borderRadius: '10px' }} className='border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
                                <img src={recordingIcon} alt='download icon not found' width={'20px'} height={'20px'} tooltip='Download audio' />
                            </div>}
                            
                            {downloadingAudio && <div style={{ borderRadius: '10px' }} className='sm:border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
                                <img src={loading} alt='loading icon not found' width={'20px'} height={'20px'} tooltip='Downloading' />
                            </div>}

                            {!downloadingReport && <div onClick={() => convertMdtoDocx(report)} style={{ borderRadius: '10px' }} className='border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
                                <img src={downloadDocIcon} alt='download icon not found' width={'20px'} height={'20px'} tooltip='Download as doc' />
                            </div>}

                            {downloadingReport && <div style={{ borderRadius: '10px' }} className='sm:border cursor-pointer  md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] md:mr-[10px] mr-[5px] border-[#E2E4E9] flex justify-center items-center'>
                                <img src={loading} alt='loading icon not found' width={'20px'} height={'20px'} tooltip='Downloading' />
                            </div>}
                            <CopyToClipboard disabled={!report.report} text={!!report.report ? reportText : "N/A"} onCopy={() => { handleCopyClicked() }}>
                                <div style={{ borderRadius: '10px' }} className='border cursor-pointer md:w-[40px] w-1/2 h-[40px] hover:border-[#CED0D5] border-[#E2E4E9] md:mr-[10px] flex justify-center items-center'>
                                    <img src={copyIcon} alt='copy icon not found' width={'20px'} height={'20px'} />
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="max-w-screen-3xl mx-auto px-4 mt-4 flex flex-col">
                    <div className='border-b border-b-1 border-b-[#E5E7EC] p-[20px] py-[10px] sm:px-[32px] sm:py-[16px] wg-ignore'>
                        <ReactMarkdown components={components}>{processedReport}</ReactMarkdown>
                    </div>
                    <div className='p-[20px] sm:p-[32px] whitespace-pre-line'>
                        <h3 className="sub-heading">Comment: </h3>
                        <div className="mb-[-5px] wg-ignore">{report.comment || "N/A"}</div>
                    </div>
                </div>
            </div>
        { showNotification && <Notification text={'Copied'} color={'bg-[#1F232E]'} /> }
        </div>
        </>
    );
};

export default Report;