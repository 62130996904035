import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CreateModal = ({ isOpen, onRequestClose, onCreate }) => {
    const initialFormData = {
        name: '',
        generation_name: '',
        description: '',
        speaker_labels: false,
        in_service: false,
        position: 0,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreate = async () => {
        setIsLoading(true);
        await onCreate(formData);
        setIsLoading(false);
        handleCancel();
    };

    const handleCancel = () => {
        setFormData(initialFormData);
        onRequestClose();
    };

    const isDisabled = !formData.name || !formData.generation_name;

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" onClick={handleCancel}></div>
            <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6 relative z-10 max-h-screen overflow-y-auto">
                <FaTimes className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={handleCancel} size={12} />
                <h2 className="text-xl font-semibold mb-4">Create Tool</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Generation Name</label>
                                <input
                                    type="text"
                                    name="generation_name"
                                    value={formData.generation_name}
                                    onChange={(e) => setFormData({ ...formData, generation_name: e.target.value })}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>
                            <div className="flex flex-col mb-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Position</label>
                                <input
                                    type="number"
                                    name="position"
                                    value={formData.position}
                                    onChange={(e) => { setFormData({ ...formData, position: parseInt(e.target.value, 10) }) }}
                                    className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
                            <textarea
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                className="px-4 py-2 border rounded-md resize-none h-32 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                required
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    name="speaker_labels"
                                    checked={formData.speaker_labels}
                                    onChange={(e) => setFormData({ ...formData, speaker_labels: e.target.checked })}
                                    className="ml-[2px] mr-2 form-checkbox h-5 w-5 text-indigo-500"
                                />
                                <label className="text-gray-700 text-sm font-bold">Speaker Labels</label>
                            </div>
                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    name="in_service"
                                    checked={formData.in_service}
                                    onChange={(e) => setFormData({ ...formData, in_service: e.target.checked })}
                                    className="ml-[2px] mr-2 form-checkbox h-5 w-5 text-indigo-500"
                                />
                                <label className="text-gray-700 text-sm font-bold">In Service</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-gray-500 text-white mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleCreate}
                            className={`px-4 py-2 border rounded-md shadow-sm focus:outline-none bg-blue-500 text-white ${isDisabled && 'opacity-80 cursor-not-allowed'}`}
                        >
                            {isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateModal;