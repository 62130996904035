import React, { useState, useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/user-store'; // Adjust the import path as necessary
import { FiMenu } from 'react-icons/fi';
import { LuLogOut as LogOut } from "react-icons/lu";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const clearUser = useUserStore((state) => state.clearUser); // Use the clearUser function from the store

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    await signOut();
    localStorage.removeItem('user_id');
    clearUser(); // Clear the user state from the store
    navigate('/');
  };

  const navLinks = [
    { to: "/reports", label: "Reports" },
    { to: "/users", label: "Users" },
    { to: "/organisations", label: "Organisations" },
    { to: "/tools", label: "Tools" },
    { to: "/transactions", label: "Transactions" },
    { to: "/templates", label: "Templates" },
    { to: "/user-templates", label: "User Templates" },
    { to: "/tool-prompts", label: "Tool Prompts" },
    { to: "/tool-fields", label: "Tool Fields" },
    { to: "/tool-inputs", label: "Tool Inputs" },
    { to: "/tool-outputs", label: "Tool Outputs" },
    { to: "/vocabulary", label: "Vocabulary" },
    { to: "/plans", label: "Plans" },
    // { to: "/api-clients", label: "API Clients" },
    // { to: "/api-runs", label: "API Runs" },
  ];

  return (
    <nav className="bg-gray-800">
      <div className="max-w-screen-3xl mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          <div className={`flex items-center ${isMenuOpen && 'justify-between w-full'}`}>
            <Link to="/" className="text-white font-semibold text-xl">
              MPAssist
            </Link>
            {isMenuOpen && (
                <div className="xl:hidden mr-6">
                  <LogOut 
                    onClick={handleLogout} 
                    className="text-gray-300 cursor-pointer hover:text-white"
                    size={22}
                    title='Log Out'
                  />
                </div>
              )}
          </div>
          <div className="hidden xl:flex 3xl:space-x-4 items-center space-x-2">
            {navLinks.map((link, index) => (
              <>
                <Link key={link.to} to={link.to} className="text-gray-300 hover:text-white">
                  {link.label}
                </Link>
                <span key={index} className='text-gray-500'>|</span>
              </>
            ))}
            <LogOut 
              onClick={handleLogout} 
              className="text-gray-300 cursor-pointer hover:text-white"
              size={24}
              title='Log Out'
            />
          </div>
          <div className="xl:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <FiMenu size={24} />
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="xl:hidden bg-gray-800 py-4">
            <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-7 gap-4">
              {navLinks.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className="text-gray-300 hover:text-white text-center"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
