import React, { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import { FaPen, FaTrash, FaPlus } from 'react-icons/fa';
import useTemplateStore from '../../store/templates-store.js';
import useToolStore from '../../store/tools-store.js';
import UpdateModal from './update-modal.jsx'; 
import CreateModal from './create-modal.jsx';
import DeleteModal from '../../components/DeleteModal.jsx'
import Loader from '../../components/Loader.jsx';
import { formatDate, renderLargeCell } from '../../utils/helper_functions.js';
import { toast } from 'react-toastify';

const columnHelper = createColumnHelper();

const Templates = () => {
  const { templates, fetchTemplates, updateTemplate, deleteTemplate, createTemplate } = useTemplateStore();
  const { tools, fetchTools } = useToolStore();

  const [globalFilter, setGlobalFilter] = useState('');
  const [expandedRows, setExpandedRows] = useState({});
  const [isEditModalOpen, setisEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchTemplates(), fetchTools()]);
      setIsLoading(false);
    }
    fetchData();
  }, [fetchTemplates, fetchTools]);

  const data = useMemo(() => {
    return templates?.map((template) => {
        const tool = tools.find((tool) => tool.id === template.tool_id);
        return {
          ...template,
          tool_name: tool?.name || " ",
        };
      })
      .sort((a, b) => a.tool_name.localeCompare(b.tool_name) || a.name.localeCompare(b.name));
  }, [templates, tools]);

  const handleCloseModal = () => {
    setIsCreateModalOpen(false);
    setisEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedTemplate(null);
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      await updateTemplate(id, updatedData);
      toast.success('Template updated successfully');
      handleCloseModal();
    } catch (error) {
      toast.error('Failed to update template. Please try again.');
      console.error('Failed to update template:', error);
    }
  };

  const handleCreateTemplate = async (data) => {
    try {
      await createTemplate(data);
      toast.success('Template created successfully');
      handleCloseModal();
    } catch (error) {
      toast.error('Failed to create template. Please try again.');
      console.error('Failed to create template:', error);
    }
  };

  const handleDeleteTemplate = async (id) => {
    try {
      await deleteTemplate(id);
      toast.success('Template deleted successfully');
      handleCloseModal();
    } catch (error) {
      toast.error('Failed to delete template. Please try again.');
      console.error('Failed to delete template:', error);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'ID',
      }),
      columnHelper.accessor('name', {
        header: 'Name',
      }),
      columnHelper.accessor('value', {
        header: 'Value',
        cell: (info) => renderLargeCell(expandedRows, setExpandedRows, info, 'description'),
      }),
      columnHelper.accessor('tool_name', {
        header: 'Tool Name',
      }),
      columnHelper.accessor('created_at', {
        header: 'Created At',
        cell: (info) => formatDate(info.getValue()),
      }),
      columnHelper.accessor('actions', {
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex space-x-4">
            <FaPen
              title="Update"
              onClick={() => {
                setSelectedTemplate(row.original);
                setisEditModalOpen(true);
              }}
              className="cursor-pointer text-blue-500"
            />
            <FaTrash
              title="Delete"
              onClick={() => {
                setSelectedTemplate(row.original);
                setIsDeleteModalOpen(true);
              }}
              className="cursor-pointer text-red-600"
            />
          </div>
        ),
      }),
    ],
    [expandedRows]
  );

  const table = useReactTable({
    data,
    columns,
    state: { globalFilter },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="max-w-screen-3xl mx-auto mt-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">Templates List</h1>
        <button
          className="flex items-center px-4 py-2 bg-black text-white rounded-md shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setIsCreateModalOpen(true)}
        >
          <FaPlus className="mr-2" /> Create New
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter}
          autoComplete="off"
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          className="px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      </div>
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-3 px-4 border-b border-gray-200 text-center text-sm font-semibold text-gray-700"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {header.column.getIsSorted()
                        ? header.column.getIsSortedDesc()
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {
              isLoading ? (
                <tr><td colSpan={columns.length}><Loader className='flex justify-center items-center h-[60vh]' /></td></tr>
              ) :
                table.getRowModel().rows.length === 0 ? (
                  <tr><td colSpan={columns.length}><p className='flex justify-center items-center h-[40vh]'>No templates found</p></td></tr>
                ) :
                  table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-50">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="py-3 px-4 border-b border-gray-200 text-sm text-gray-700">
                          <div className="flex justify-center">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      ))}
                    </tr>
                  ))}
          </tbody>
        </table>
      </div>
      <CreateModal
        isOpen={isCreateModalOpen}
        onRequestClose={handleCloseModal}
        onCreate={handleCreateTemplate}
        tools={tools}
      />
        <UpdateModal
          isOpen={isEditModalOpen}
          onRequestClose={handleCloseModal}
          template={selectedTemplate}
          onUpdate={handleUpdate}
          tools={tools}
        />
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseModal}
          onDelete={handleDeleteTemplate}
          data={{...selectedTemplate, dataName: 'template'}}
        />
    </div>
  );
};

export default Templates;
